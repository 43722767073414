<template>
   <div class="block">
      <input v-bind="$attrs" :type="inputType" :value="value" class="input" :class="className" @input="onChange" />
      <!--       <input
         v-bind="$attrs"
         :type="inputType"
         :value="value"
         class="input"
         :class="className"
         @input="onChange"
         ref="input"
      /> -->
      <button @click.stop="toggleIsShowPass" class="buttonImg" type="button" v-if="hasShowIcon && type === 'password'">
         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
            <path
               d="M7.99935 0C4.66602 0 1.81935 2.07333 0.666016 5C1.81935 7.92667 4.66602 10 7.99935 10C11.3327 10 14.1793 7.92667 15.3327 5C14.1793 2.07333 11.3327 0 7.99935 0ZM7.99935 8.33333C6.15935 8.33333 4.66602 6.84 4.66602 5C4.66602 3.16 6.15935 1.66667 7.99935 1.66667C9.83935 1.66667 11.3327 3.16 11.3327 5C11.3327 6.84 9.83935 8.33333 7.99935 8.33333ZM7.99935 3C6.89268 3 5.99935 3.89333 5.99935 5C5.99935 6.10667 6.89268 7 7.99935 7C9.10602 7 9.99935 6.10667 9.99935 5C9.99935 3.89333 9.10602 3 7.99935 3Z"
               fill="#445275"
            />
         </svg>
      </button>
      <div class="block error-img" v-if="isError && errorMessage">
         <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
               d="M5.99935 0.166504C2.77935 0.166504 0.166016 2.77984 0.166016 5.99984C0.166016 9.21984 2.77935 11.8332 5.99935 11.8332C9.21935 11.8332 11.8327 9.21984 11.8327 5.99984C11.8327 2.77984 9.21935 0.166504 5.99935 0.166504ZM6.58268 8.9165H5.41602V7.74984H6.58268V8.9165ZM6.58268 6.58317H5.41602V3.08317H6.58268V6.58317Z"
               fill="#E8132A"
            />
         </svg>
         <span class="err-btn-message">{{ errorMessage }}</span>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      type: {
         type: String,
      },
      isError: {
         type: Boolean,
      },
      errorMessage: {
         type: String,
      },
      hasShowIcon: {
         type: Boolean,
      },
      value: {},
   },
   computed: {
      className() {
         return {
            error: this.isError,
         };
      },
      inputType() {
         if (this.type !== 'password') return this.type;

         return this.isShowPass ? 'text' : this.type;
      },
   },
   //TODO: REMOVE COMMENTS and mounted deleted
   mounted() {
      console.log(this.$attrs);
      console.log('props', this.$props);
   },

   /*    watch: {
      isShowPass() {
         this.$refs.input.focus();
      },
   }, */

   data() {
      return {
         isShowPass: false,
         /*   input: null, */
      };
   },
   methods: {
      onChange(e) {
         this.$emit('input', e.target.value);
      },
      toggleIsShowPass() {
         this.isShowPass = !this.isShowPass;
      },
   },
};
</script>

<style coped lang="sass">
@import '../../sass/_variables'

.block
   position: relative
   max-width: 356px
   width: 100%
.buttonImg
   position: absolute
   top:12px
   right:16px

.input
   max-width: 356px
   width: 100%
   padding: 12px 45px  12px 16px
   border: 1px solid $input-border
   border-radius:10px
   background-color: $default-white
   font-size: 14px
   outline: none
   transition: background-color 0.5s ease
   &:placeholder-shown:focus
      &+.buttonImg
         opacity: .2

   // color: $text-desabled
   &:hover
      background-color: $surface
   &:hover::placeholder
      color:$shape-hover
   &:focus
      border-color: transparent
      box-shadow:0px 0px 0px 5px $border-active

.error
   border-color:$shape-alert
   // &::placeholder
   //    color: transparent

   // &:hover::placeholder
   //    color: transparent
   &:focus
      border-color: $shape-alert
      outline: $shape-alert
   &:focus::placeholder
      color: transparent


.err-btn-message
      color:$shape-alert
      font-size: 11px
      display: inline-block
      margin-left: 5px

.error-img
   display: flex
   align-items: center
   margin-top: 8.5px
</style>
