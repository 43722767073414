<template>
	<div class="container-registerphone-sleep">
		<div class="registerphone-content">
			<div class="img-box">
				<img src="../assets/img/sleepNewLogo.png" alt="" />
			</div>
			<div class="title-register-phone">
				<p>Welcome to</p>
				<p>Sleep-well.coach</p>
			</div>
			<div class="form-register-phone">
				<form @submit.prevent="onConfirm">
					<TheInput class="margin-inp" :isError="false" placeholder="Code from SMS" v-model="value"></TheInput>
					<SWButton class="margin-btn" type="submit" size="big" variant="secondari">Finish</SWButton>
				</form>
				<SWButton :disabled="!isResendAllwed" @click="resendSMS" class="margin-btn"
					>Resend SMS CODE {{ timeFormat ? `in ${timeFormat}` : '' }}</SWButton
				>
			</div>
			<div class="footer-sleep-register">
				<div class="paragraf">
					<p>Signing in for a Sleep-well.coach account means you agree to the</p>
				</div>
				<FooterRegistartion />
			</div>
		</div>
	</div>
</template>

<script>
import { SWButton } from 'sw-ui-kit';
import TheInput from '../components/UI/TheInput.vue';
import FooterRegistartion from '../components/FooterRegistartion.vue';
import appUrl from '../store/appUrl';

const TIME_COUNT_FROM = 60 * 1000;

export default {
	components: {
		TheInput,
		SWButton,
		FooterRegistartion,
	},

	data() {
		return {
			value: '',
			timeLeft: 0,
			intervalId: null,
			timeStarted: null,
			isResendAllwed: true,
		};
	},

	mounted() {
		if (this.$route.params?.phone) {
			sessionStorage.phone = this.$route.params?.phone;
		}
		this.startTimer();
	},

	computed: {
		timeFormat() {
			if (!this.timeLeft) return null;
			const totalSeconds = Math.ceil(Math.abs(this.timeLeft) / 1000);
			const minutes = Math.floor(totalSeconds / 60);
			const seconds = totalSeconds % 60;

			return `${minutes.toString().length === 1 ? `0${minutes ? minutes : 0}` : minutes}:${
				seconds.toString().length === 1 ? '0' + seconds : seconds
			}`;
		},
	},

	methods: {
		async onConfirm() {
			try {
				let data = await this.$http.post('auth/verify/phone', { hash: this.value });
				window.location.replace(appUrl + `?token=${data.data.token}`);
			} catch (error) {
				alert('Wrong code, try again');
			}
		},

		startTimer() {
			this.timeLeft = TIME_COUNT_FROM;
			this.isResendAllwed = false;
			this.intervalId = setInterval(() => {
				if (!this.startTime) {
					this.startTime = Date.now();
				}
				this.timeLeft = Date.now() - this.startTime - TIME_COUNT_FROM;
				if (Date.now() - this.startTime >= TIME_COUNT_FROM) {
					this.stopTimer();
				}
			}, 500);
		},

		stopTimer() {
			clearInterval(this.intervalId);
			this.timeLeft = 0;
			this.startTime = null;
			this.isResendAllwed = true;
		},

		async resendSMS() {
			try {
				await this.$http.post(
					'auth/register/resend/phone',
					{
						phone: sessionStorage.phone,
					},
					{ withCredentials: true },
				);
				this.startTimer();
			} catch {
				alert('SMS sending limit per day exceeded.');
			}
		},
	},

	destroyed() {
		this.stopTimer();
	},
};
</script>

<style lang="sass" scoped>
.container-registerphone-sleep
	width: 100%
	height: 100%
	margin: 0 auto
	max-width: 1246px
	padding: 40px 20px
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	@media screen and (max-width:380px)
		padding: 15px 20px

.registerphone-content
	max-width: 358px
	width: 100%
.title-register-phone
	margin-top: 24px
	margin-bottom: 32px
	text-align: center
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
.img-box
	max-width: 128px
	width: 100%
	margin: 0 auto
.footer-sleep-register
	color: #828282
	font-size: 12px
	font-weight: 400
	line-height: 16px
.margin-inp
	margin-bottom: 16px
.margin-btn
	margin-bottom: 16px
</style>
